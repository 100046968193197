import { Injectable } from '@angular/core';
import { environment } from 'projects/evolutics-shared-lib/src/environments/environment';
import { ApiService } from '@Services/api.service';
import { ICode } from '../shared/models/admin-code.interface';
import { ISearchResult } from '../Tasks/task-extras/task-setup.interface';
import { ICodeDescription, ICodeTitle, ISearchResponse2 } from '@Shared/models/index.model';
import { IContentSearchItem } from 'projects/evolutics-admin-ui/src/app/client-libraries/client-libraries-comp/client-library-layout/client-library-layout.service';
import { Config } from '@configs/index.config';
import { Observable, merge, tap } from 'rxjs';
import { JMenuItem } from '../shared/models/index.model';
import { UtilityService } from '@Services/utility.service';

@Injectable({
  providedIn: 'root',
})
export class AdminAPIService {
  baseUrl = environment.apiBaseUrl + '/admin/rest/api';
   baseUrl2 = environment.apiBaseUrl + '/admin/rest/code/';
  constructor(public apiService: ApiService, private uS: UtilityService) {}

  /**
   * Create a new API
   * @param data: API object to be submitted
   * @returns record of created API object
   */
  createNewAPI = (data: any) => {
    return this.apiService.post<any>(this.baseUrl, data,
      Config.headerForJupiterCrossPlatformRequest);
  };

  /**
   * Update an API
   * @param id Id of the record
   * @param data: API object to be submitted
   * @returns record of updates API object
   */
  updateAPI = (id, data: any) => {
    return this.apiService.put<any>(this.baseUrl + `/${id}`, data,
    Config.headerForJupiterCrossPlatformRequest);
  };

  /**
   * Searches for an API by parameters
   * @param query Query parameters for search
   * @returns Search Response
   */
  searchApiDoc = (query: any) => {
    return this.apiService.get<ISearchResult>(this.baseUrl + '/search', query,
    Config.headerForJupiterCrossPlatformRequest);
  };

  /**
   * get for an API by code
   * @param query Query parameters for search
   * @returns Search Response
   */
  getApiByCode = (code: any) => {
    return this.apiService.get(this.baseUrl + `/${code}`,
    {},Config.headerForJupiterCrossPlatformRequest);
  };

  searchApiWithCache(query: any) {
    return this.apiService.getWithLocalCache(this.baseUrl + '/search', query,
    Config.headerForJupiterCrossPlatformRequest);
  }


  /**
   * search api index
   * @param query search query
   * @returns observable of list
   */
  searchAPIIndex(query: any) {
    return this.apiService.get<any[]>(this.baseUrl + '/index/search', query,
    Config.headerForJupiterCrossPlatformRequest);
  }

   getAllGroupsAndSubGroupAsMenus() {
    return new Observable<JMenuItem[]>((sub) => {
      this.getCodesBySubGroup('Api_group').subscribe((codes) => {
        console.log(codes)
        const menu: JMenuItem[] = codes.map((code) => {
          return {
            label: this.uS.toTitleCase(code.title),
            id: code.code,
            items: [],
            parentID: code.code
          };
        });
        sub.next(menu);
        merge(
          ...codes.map((code, codeIndex) =>
            this.getCodesBySubGroup('Api_subgroup').pipe(
              tap((subGroups: ICodeTitle[]) => {
                menu[codeIndex].subs = subGroups
                  .filter((x) => x.code)
                  .map<JMenuItem>((subCodes) => {
                    return {
                      label: subCodes.title.replaceAll('_',' '),
                      id: subCodes.code,
                      items: [],
                      parentID: code.code,
                    };
                  }) as any[];
              })
            )
          )
        )
        .subscribe({
          next: (codes) => {
          //  console.log(menu)
            sub.next(menu);
          },
          error: (e) => {
            console.error(e);
          },
          complete: () => {
            sub.complete();
          },
        });
      });
    });
  }

  getCodesBySubGroup = (codeSubgroup) =>
    this.apiService.getWithLocalCache(
      `${this.baseUrl2}sub/group/${codeSubgroup?.toUpperCase()}`,
      {},
      Config.headerForJupiterCrossPlatformRequest
    );
}
